import React from "react";
import logoInsightFeed from "./assets/img/InsightFeed.svg";
import { useAppContext } from "./Store/Store";

export default function Footer() {
  const { logoRedirectUrl, privacyPolicyUrl } = useAppContext();

  const handlePrivacyPolicyClick = () => {
    if (privacyPolicyUrl) {
      window.open(privacyPolicyUrl, "_blank");
    } else {
      alert("Privacy policy is not available.");
    }
  };

  return (
    <footer
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // padding: "20px 0",
      }}
    >
      <a
        href={logoRedirectUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          height: "45%",
          maxWidth: "100%",
          objectFit: "contain",
        }}
      >
        <img src={logoInsightFeed} alt="InsightFeed Logo" />
      </a>
      <button
        onClick={handlePrivacyPolicyClick}
        style={{
          fontSize: "12px",
          textDecoration: "underline",
          color: "grey",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          marginTop: "20px",
        }}
      >
        Privacy Policy
      </button>
    </footer>
  );
}
