import React from "react";
import { useAppContext } from "./Store/Store";

export default function Header() {
  const { appState, companyLogo, tokenIndication } = useAppContext();

  return (
    <header
      style={{
        position: "relative",
        width: "100%",
        height: "22.5%",
        fontSize: "22px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {companyLogo && (
          <img
            src={companyLogo}
            alt="Company Logo"
            style={{
              maxWidth: "250px",
              maxHeight: "125px",
            }}
          />
        )}
      </div>
      
      {appState === "mail-form" ? (
        <div
        style={{
          position: "absolute",
          top: "calc(45% + 40%)",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          textAlign: "center",
          fontSize: "70%",
        }}
      >Welcome to Nordika Ski test</div>
      ) : (
        <div
        style={{
          position: "absolute",
          top: "calc(45% + 40%)",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          textAlign: "center",
          fontSize: "70%",
        }}
        dangerouslySetInnerHTML={{ __html: tokenIndication }}
      ></div>
      )}
    </header>
  );
}
