import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import "./assets/css/style.css";
import { AppProvider, useAppContext } from "./Store/Store";
import FilesList from "./FilesList";

function AppContent() {
  
  const { appState, fetchInitialData } = useAppContext();

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <div id="root" className={appState === "processing" ? "show-gradient" : ""}>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<AppContent />} />
          <Route path="/files" element={<FilesList />} />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;
