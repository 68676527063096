// store.js
import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const AppContext = createContext();

export function AppProvider({ children }) {
  const [appState, setAppState] = useState("initial");
  const [logoRedirectUrl, setLogoRedirectUrl] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [token, setToken] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(null);
  const [tokenIndication, setTokenIndication] = useState("");
  const [showFiles, setShowFiles] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [mail, setMail] = useState("");

  const fetchInitialData = async () => {
    try {
      const response1 = await axios.get("/api/get_logo_redirect_url");
      setLogoRedirectUrl(response1.data.logoRedirectUrl);
      const tokenValue = new URLSearchParams(window.location.search).get("token");
      if (!tokenValue) {
        setAlertMessage("MISSING TOKEN");
        setShowAlert(true);
        return;
      }
      setToken(tokenValue);
      const response2 = await axios.post(`/api/validate_token`, { token: tokenValue });
      setIsTokenValid(response2.data.isTokenValid);
      if (!response2.data.isTokenValid) {
        setAlertMessage("NOT VALID TOKEN");
        setShowAlert(true);
        return;
      }
      const response3 = await axios.get(`/api/get_token_info?token=${tokenValue}`);
      setCompanyLogo(response3.data.logo);
      setPrivacyPolicyUrl(response3.data.privacy_policy_url);
      setTokenIndication(response3.data.token_indication);
      setShowFiles(response3.data.show_files);
      await fetchFiles(tokenValue);
      setProgressBar(0);
    } catch (error) {console.error("Error fetching initial data or config:", error);}
  };

  const fetchFiles = async (tokenValue) => {
    try {
      const response = await axios.get(`/api/files?token=${tokenValue}`);
      const filesWithDisplayNames = response.data.map((file) => {
        let displayFilename = "";
        if (file.type === "text") {
          displayFilename = "Text Review";
        } else {
          displayFilename = "Recording";
        }
        return { ...file, displayFilename };
      });
      setFiles(filesWithDisplayNames);
    } catch (error) {console.error("Error fetching files:", error);}
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        logoRedirectUrl,
        privacyPolicyUrl,
        alertMessage,
        showAlert,
        token,
        isTokenValid,
        companyLogo,
        tokenIndication,
        showFiles,
        progressBar,
        files,
        mail,
        setAppState,
        setAlertMessage,
        setShowAlert,
        setProgressBar,
        fetchInitialData,
        fetchFiles,
        setFiles,
        setMail
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
