import React, { useState } from "react";
import { ReactComponent as YoufeedStar } from "../img/YoufeedStar.svg";

const StarRating = ({ rating, setRating }) => {
  const [hover, setHover] = useState(null);
  const containerStyle = {
    display: "flex",
    marginTop: "10px",
  };

  return (
    <div style={containerStyle}>
      {[1, 2, 3, 4, 5].map((star) => {
        const isActive = star <= (hover || rating);
        return (
          <YoufeedStar
            key={star}
            onMouseEnter={() => setHover(star)}
            onMouseLeave={() => setHover(null)}
            onClick={() => setRating(star)}
            style={{
              cursor: "pointer",
              width: "46px",
              height: "45px",
              transition: "fill 200ms",
              color: isActive ? "#FFD700" : "#e4e5e9",
            }}
          />
        );
      })}
    </div>
  );
};

export default StarRating;
